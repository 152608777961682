<template>
	<div class="call-filter">
		<button
			id="call-filter-toggle"
			class="call-filter-toggle btn btn-outline-dark"
			aria-haspopup="listbox"
			aria-label="Call filter"
			@click.prevent="onToggleClick"
		>
			<div class="btn-ripple"></div>
			<span v-if="hasFilter" class="btn-label">
				Filters ({{ selected }})
			</span>
			<span v-else class="btn-label">
				Filters
			</span>

			<font-awesome-icon
				class="btn-icon"
				:icon="['fal', show ? 'chevron-up' : 'chevron-down']"
			/>
		</button>
		<transition name="fade">
			<div v-if="show" class="call-filter-dropdown" :class="{ show }">
				<div
					id="call-filter-list"
					tabindex="-1"
					role="listbox"
					aria-label="Choose a call filter"
					aria-multiselectable="true"
					class="call-filter-dropdown-list"
					@click.stop
				>
					<div class="call-filter-toggle-all">
						<button
							v-if="selected < totalOptions"
							type="button"
							class="btn btn-dark"
							@click="selectAll"
						>
							<div class="btn-ripple"></div>
							<span class="btn-label">Select All</span>
						</button>
						<button
							v-else
							type="button"
							class="btn btn-dark"
							@click="deselectAll"
						>
							<div class="btn-ripple"></div>
							<span class="btn-label">Deselect All</span>
						</button>
					</div>

					<div class="call-filter-header">
						Filters
					</div>

					<div
						id="call-filter-item-urgent"
						class="call-filter-item"
						role="option"
					>
						<input
							id="call-filter-urgent"
							v-model="urgent"
							type="checkbox"
							:value="true"
							class="call-filter-option-checkbox"
						/>

						<label
							class="call-filter-option-label"
							for="call-filter-urgent"
						>
							Urgent
						</label>
					</div>

					<div
						id="call-filter-item-non-urgent"
						class="call-filter-item"
						role="option"
					>
						<input
							id="call-filter-non-urgent"
							v-model="nonUrgent"
							type="checkbox"
							:value="true"
							class="call-filter-option-checkbox"
						/>

						<label
							class="call-filter-option-label"
							for="call-filter-non-urgent"
						>
							Non-Urgent
						</label>
					</div>

					<div
						id="call-filter-item-complete"
						class="call-filter-item"
						role="option"
					>
						<input
							id="call-filter-complete"
							v-model="filters"
							type="checkbox"
							value="complete"
							class="call-filter-option-checkbox"
						/>

						<label
							class="call-filter-option-label"
							for="call-filter-complete"
						>
							Complete
						</label>
					</div>

					<div
						id="call-filter-item-urgent"
						class="call-filter-item"
						role="option"
					>
						<input
							id="call-filter-fallback"
							v-model="filters"
							type="checkbox"
							value="fallback"
							class="call-filter-option-checkbox"
						/>

						<label
							class="call-filter-option-label"
							for="call-filter-fallback"
						>
							Fallback
						</label>
					</div>

					<div
						id="call-filter-item-incomplete"
						class="call-filter-item"
						role="option"
					>
						<input
							id="call-filter-incomplete"
							v-model="filters"
							type="checkbox"
							value="incomplete"
							class="call-filter-option-checkbox"
						/>

						<label
							class="call-filter-option-label"
							for="call-filter-incomplete"
						>
							New
						</label>
					</div>

					<div
						id="call-filter-item-noted"
						class="call-filter-item"
						role="option"
					>
						<input
							id="call-filter-noted"
							v-model="filters"
							type="checkbox"
							value="noted"
							class="call-filter-option-checkbox"
						/>

						<label
							class="call-filter-option-label"
							for="call-filter-noted"
						>
							Notes
						</label>
					</div>

					<div
						id="call-filter-item-urgent"
						class="call-filter-item"
						role="option"
					>
						<input
							id="call-filter-Voicemail"
							v-model="filters"
							type="checkbox"
							value="rsm_message"
							class="call-filter-option-checkbox"
						/>

						<label
							class="call-filter-option-label"
							for="call-filter-Voicemail"
						>
							Voicemail
						</label>
					</div>

					<div
						id="call-filter-item-urgent"
						class="call-filter-item"
						role="option"
					>
						<input
							id="call-filter-dismiss"
							v-model="filters"
							type="checkbox"
							value="dismiss"
							class="call-filter-option-checkbox"
						/>

						<label
							class="call-filter-option-label"
							for="call-filter-dismiss"
						>
							Dismiss
						</label>
					</div>

					<div
						v-show="callTypes.length > 0"
						class="call-filter-header"
					>
						Call Types
					</div>

					<div
						v-for="type in callTypes"
						:id="`call-type-item-${type.id}`"
						:key="type.id"
						class="call-filter-item"
						role="option"
					>
						<input
							:id="`call-type-${type.id}`"
							v-model="types"
							type="checkbox"
							:value="type"
							class="call-filter-option-checkbox"
						/>

						<label
							class="call-filter-option-label"
							:for="`call-type-${type.id}`"
						>
							{{ type.name }}
						</label>
						<span class="call-filter-item-badge">
							{{ type.total }}
						</span>
					</div>

					<div class="call-filter-submit">
						<button
							type="button"
							class="btn btn-outline-warning w-100"
							:disabled="applyingFilter"
							@click.prevent.stop="onApplyFiltersClick"
						>
							<div class="btn-ripple"></div>
							<span class="btn-label m-2">Apply Filters</span>
							<font-awesome-icon
								v-if="applyingFilter"
								spin
								fixed-width
								size="1x"
								margin="0 0 0 10px"
								:icon="['far', 'spinner-third']"
							/>
						</button>
					</div>
				</div>
			</div>
		</transition>
	</div>
</template>
<script>
import { mapActions, mapGetters, mapState } from 'vuex'

/**
 * The escape numeric key code.
 *
 * @type {Number}
 */
const ESCAPE_KEY_CODE = 27

/**
 * The total number of statuses + urgent/non-urgent filters.
 *
 * @type {Number}
 */
const TOTAL_FILTERS = 6

export default {
	/**
	 * The component's computed properties.
	 *
	 * @type {Object}
	 */
	computed: {
		/**
		 * Number of selected items on dropdown.
		 *
		 * @return {Number}
		 */
		selected() {
			let filters = 0

			if (this.urgent) {
				filters++
			}

			if (this.nonUrgent) {
				filters++
			}
			const filtersLength = this.filters ? this.filters.length : 0
			const typesLength = this.types ? this.types.length : 0
			return filtersLength + typesLength + filters
		},

		/**
		 * Total number of options.
		 *
		 * @return {Number}
		 */
		totalOptions() {
			return this.callTypes.length + TOTAL_FILTERS
		},

		...mapGetters('calls', ['hasFilter']),

		...mapState({
			activeFilters: state => state.calls.filters.statuses,
			activeTypes: state => state.calls.filters.types,
			activeUrgent: state => state.calls.filters.urgent,
			activeNonUrgent: state => state.calls.filters.nonUrgent,
		}),
	},

	/**
	 * The component's local methods.
	 *
	 * @type {Object}
	 */
	methods: {
		/**
		 * Add the escape keydown event listeners.
		 *
		 * @return {void}
		 */
		addEventListeners() {
			document.addEventListener('click', this.closeDropdown)
			document.addEventListener('keydown', this.onEscapeKeyDown)
		},

		/**
		 * Handle a global click event outside of the dropdown toggler.
		 *
		 * @return {void}
		 */
		closeDropdown() {
			if (this.show) {
				this.show = false
			}

			setTimeout(() => this.removeEventListeners(), 0)
		},

		/**
		 * Remove all selected filters and types.
		 *
		 * @return {void}
		 */
		deselectAll() {
			this.types = []
			this.filters = []
			this.urgent = false
			this.nonUrgent = false
		},

		/**
		 * Get the all of the filters as a store payload object.
		 *
		 * @return {Object}
		 */
		getFiltersAsObject() {
			return {
				nonUrgent: this.nonUrgent,
				statuses: this.filters,
				types: this.types,
				urgent: this.urgent,
			}
		},

		/**
		 * Determine if the given keycode is the escape key.
		 *
		 * @param {Number} keyCode
		 * @return {Boolean}
		 */
		isEscapeKeyCode(keyCode) {
			return keyCode === ESCAPE_KEY_CODE
		},

		/**
		 * Apply the filters and load the calls.
		 *
		 * @return {void}
		 */
		async onApplyFiltersClick() {
			const payload = {
				fromFilters: true,
				callId: this.$route.params.sid,
			}
			await this.setFilters(this.getFiltersAsObject())
			this.applyingFilter = true

			await this.fetchCalls(payload).then(() => {
				this.closeDropdown()
				this.applyingFilter = false
			})
		},

		/**
		 * Handle the escape keydown event.
		 *
		 * @returns {void}
		 */
		onEscapeKeyDown(event) {
			if (this.show && this.isEscapeKeyCode(event.keyCode)) {
				this.show = false

				this.removeEventListeners()
			}
		},

		/**
		 * Handle the dropdown click event.
		 *
		 * @return {void}
		 */
		onToggleClick() {
			this.show = !this.show

			setTimeout(() => {
				return this.show
					? this.addEventListeners()
					: this.removeEventListeners()
			}, 0)
		},

		/**
		 * Mark all types and filters as selected.
		 *
		 * @return {void}
		 */
		selectAll() {
			this.urgent = true
			this.nonUrgent = true
			this.types = [...this.callTypes]
			this.filters = [
				'complete',
				'incomplete',
				'noted',
				'fallback',
				'rsm_message',
				'dismiss',
			]
		},

		/**
		 * Remove the global event listeners.
		 *
		 * @return {void}
		 */
		removeEventListeners() {
			document.removeEventListener('keydown', this.onEscapeKeyDown)
			document.removeEventListener('click', this.closeDropdown)
		},

		...mapActions('calls', {
			fetchCalls: 'get',
			setFilters: 'setFilters',
			clearAllFilters: 'clearAllFilters',
			setUrgentFilter: 'setUrgentFilter',
			setNonUrgentFilter: 'setNonUrgentFilter',
		}),
	},

	/**
	 * The component's name used for debugging.
	 *
	 * @type {String}
	 */
	name: 'CallFilterDropdown',

	/**
	 * The component's inherited properties.
	 *
	 * @type {Object}
	 */
	props: {
		/**
		 * The partner's call types collection.
		 *
		 * @type {Array}
		 */
		callTypes: {
			type: Array,
			default() {
				return []
			},
		},
	},

	/**
	 * The component's property watchers.
	 *
	 * @type {Object}
	 */
	watch: {
		/**
		 * Sync the local selected filters with the store's filter state.
		 *
		 * @return {void}
		 */

		activeFilters(newVal) {
			this.filters = newVal || []
		},

		/**
		 * Sync the local non-urgent checbox with the store's non-urgent filter state.
		 *
		 * @return {void}
		 */
		activeNonUrgent(newVal) {
			this.nonUrgent = newVal || false
		},

		/**
		 * Sync the local types with the store's active type filters.
		 *
		 * @return {void}
		 */
		activeTypes(newVal) {
			this.types = newVal || []
		},

		/**
		 * Sync the local urgent checkbox with the store's urgent filter state.
		 *
		 * @return {void}
		 */
		activeUrgent(newVal) {
			this.urgent = newVal || false
		},

		/**
		 * Sync the local types & filters with the store when toggled.
		 *
		 * @return {void}
		 */
		show() {
			this.types = this.activeTypes || []
			this.filters = this.activeFilters || []
			this.urgent = this.activeUrgent || false
			this.nonUrgent = this.activeNonUrgent || false
		},
	},

	/**
	 * The component's before destroy lifecycle hook.
	 *
	 * @return {void}
	 */
	beforeDestroy() {
		this.removeEventListeners()
		this.clearAllFilters()
	},

	mounted() {
		this.types = this.activeTypes || []
		this.filters = this.activeFilters || []
		this.urgent = this.activeUrgent || false
		this.nonUrgent = this.activeNonUrgent || false
	},

	/**
	 * Get the component's initial state.
	 *
	 * @return {Object}
	 */
	data() {
		return {
			filters: this.activeFilters || [],
			nonUrgent: this.activeNonUrgent || false,
			show: false,
			types: this.activeTypes || [],
			urgent: this.activeUrgent || false,
			applyingFilter: false,
		}
	},
}
</script>
