<template>
	<div class="flex" :class="{ 'calls-show': isCallShowRoute }">
		<div ref="inbox" class="inbox">
			<inbox-header
				:sort="sort"
				:is-loading="isLoading"
				:has-list-loader="busy"
				@sort="onSort"
			/>
			<div v-for="(date, key) in dates" :key="key" class="inbox-group">
				<div v-if="date === today" class="inbox-group-title">
					<font-awesome-icon :icon="['far', 'calendar-day']" />
					Today
				</div>
				<div v-else-if="date === yesterday" class="inbox-group-title">
					<font-awesome-icon :icon="['far', 'calendar-day']" />
					Yesterday
				</div>
				<div v-else class="inbox-group-title">
					<font-awesome-icon :icon="['far', 'calendar-day']" />

					{{ date }}
				</div>

				<inbox-card
					v-for="call in groups[date]"
					:key="call.id"
					:call="call"
				/>
			</div>
			<div
				v-if="!dates.length && !isLoading"
				class="flex justify-center items-center h-64"
			>
				<p class="text-gray-500 text-xl">No records found.</p>
			</div>
			<loader
				v-if="hasNextPage && !hasFetchedAllPages"
				:enabled="autoload"
				:threshold="0.65"
				@intersect="fetchNextPage"
				@click.prevent="onLoadMoreClick"
			>
				{{ busy ? 'Please wait...' : 'Load more' }}
				<font-awesome-icon
					v-if="busy"
					class="ml-2"
					:icon="['far', 'circle-notch']"
					spin
				/>
				<font-awesome-icon
					v-else
					class="ml-2"
					:icon="['far', 'chevron-down']"
				/>
			</loader>
		</div>
		<router-view />
	</div>
</template>

<script>
import moment from 'moment'
import { sortBy } from 'lodash'
import Loader from '@/components/Loader'
import { mapActions, mapGetters } from 'vuex'
import InboxCard from '@/components/InboxCard'
import InboxHeader from '@/components/InboxHeader'
import Bugsnag from '@bugsnag/js'

/**
 * The format used to display call dates.
 *
 * @type {String}
 */
const DATE_FORMAT = 'MM/DD/YYYY'

export default {
	/**
	 * The component's registered child components.
	 *
	 * @type {Object}
	 */
	components: {
		InboxCard,
		InboxHeader,
		Loader,
	},

	/**
	 * The component's computed properties.
	 *
	 * @type {Object}
	 */
	computed: {
		/**
		 * Sort the calls grouped by date/day occured.
		 *
		 * @return {Array}
		 */
		dates() {
			return Object.keys(this.groups).sort((a, b) => {
				if (this.sort === 'desc') {
					return this.toUnix(a) - this.toUnix(b)
				}

				return this.toUnix(b) - this.toUnix(a)
			})
		},

		/**
		 * Get all calls grouped together by date.
		 *
		 * @return {Object}
		 */
		groups() {
			const groups = this.calls.reduce((accu, call) => {
				const formatted = moment
					.utc(call.created_at)
					.local()
					.format(DATE_FORMAT)

				accu[formatted] = (accu[formatted] || []).concat(call)

				return accu
			}, {})

			Object.keys(groups).forEach(key => {
				groups[key] = sortBy(groups[key], ['created_at'])

				if (this.sort === 'asc') {
					groups[key].reverse()
				}
			})

			return groups
		},

		/**
		 * Determine if the another page can be loaded.
		 *
		 * @return {Boolean}
		 */
		hasNextPage() {
			const { current_page, last_page } = this.meta

			return current_page > 0 && current_page < last_page
		},

		/**
		 * Determine if the current page is the call show route.
		 *
		 * @return {Boolean}
		 */
		isCallShowRoute() {
			return this.$route.name === 'app.calls.show'
		},

		/**
		 * Enables health check functionality.
		 * @returns {boolean}
		 */
		enableHealthCheck() {
			return Boolean(this.healthCheckThreshold)
		},

		/**
		 * Returns threshold time for refresh.
		 * @returns {boolean}
		 */
		healthCheckThreshold() {
			return this.activePartner?.health_check_threshold
		},

		...mapGetters({
			calls: 'calls/calls',
			meta: 'calls/meta',
			isLoading: 'calls/isLoading',
			activePartner: 'partners/active',
		}),
	},

	/**
	 * The component's local methods.
	 *
	 * @type {Object}
	 */
	methods: {
		updateTodayAndYesterday() {
			const now = moment()
			this.today = now.startOf('day').format('MM/DD/YYYY')
			this.yesterday = now
				.subtract(1, 'day')
				.startOf('day')
				.format('MM/DD/YYYY')
		},
		/**
		 * Fetch the call and call types data from the API.
		 *
		 * @return {void}
		 */
		async getCallData() {
			try {
				await [
					this.getCalls(),
					this.getCallTypes(),
					this.getIncompletePagingCount(),
				]
			} catch (e) {
				this.$alert.error('default.error')
			}
		},

		/**
		 * Fetch more calls when it's needed.
		 *
		 * @return {void}
		 */
		async fetchNextPage() {
			if (!this.autoload || this.busy) {
				return
			}

			if (!this.hasNextPage) {
				this.hasFetchedAllPages = true
				this.autoload = false
			}

			this.busy = true

			const { scrollTop } = this.$refs.inbox

			try {
				await this.getCalls(this.meta.current_page + 1)
			} catch (e) {
				this.$alert.error('default.error')
			}

			this.busy = false

			await this.$nextTick()

			this.$refs.inbox.scrollTop = scrollTop + 24
		},

		/**
		 * Handle the the infinite scroll load-more button being clicked.
		 *
		 * @return {void}
		 */
		onLoadMoreClick() {
			if (!this.busy) {
				this.autoload = true

				this.fetchNextPage()
			}
		},

		/**
		 * Change the sort direction.
		 *
		 * @return {void}
		 */
		onSort() {
			if (this.sort === 'asc') {
				return (this.sort = 'desc')
			}

			this.sort = 'asc'
		},

		/**
		 * Convert the date string to a unix timestamp.
		 *
		 * @return {Number}
		 */
		toUnix(date) {
			return moment(date, DATE_FORMAT).unix()
		},

		/**
		 * Performs a health check by comparing recent call IDs from the local state with those from the server.
		 * - Retrieves the most recent call IDs from the local Vuex store.
		 * - Makes an API call to fetch the latest call IDs from the server based on the active partner and a specified refresh interval.
		 * - Filters for any call IDs present on the server but missing locally.
		 * - If any discrepancies are found, triggers an update to fetch the latest call data.
		 *
		 * @param {number} refreshIntervalInMinutes - The interval in minutes for how often the health check should refresh.
		 */
		async performHealthCheck(refreshIntervalInMinutes) {
			const { calls, partners } = this.$store.state
			const recentIds = calls.ids
			const response = await this.$api
				.eventLogger()
				.healthCheck(partners.active, refreshIntervalInMinutes)
			const idsFromServer = response.get('call_ids', [])
			const missingIds = idsFromServer.filter(
				id => !recentIds.includes(id)
			)
			if (missingIds.length > 0) {
				await this.getCalls()

				// Notify missing ids on Bugsnag
				Bugsnag.notify(
					new Error(
						'IDs: ' +
							missingIds.toString() +
							' are missing and needed to be fetched.'
					)
				)
			}
		},

		...mapActions({
			getCalls: 'calls/get',
			getCallTypes: 'callTypes/get',
			getIncompletePagingCount: 'pages/getIncompletePagingCount',
		}),
	},

	/**
	 * The component's name used for debugging.
	 *
	 * @type {String}
	 */
	name: 'Calls',

	/**
	 * The component's property watchers.
	 *
	 * @type {Object}
	 */
	watch: {
		/**
		 * Refresh the loaded call data when the active partner changes.
		 *
		 * @param {Object} to
		 * @param {Object} from
		 * @return {void}
		 */
		$route(to, from) {
			if (to.params.partner !== from.params.partner) {
				this.getCallData()
			}
		},
	},

	/**
	 * The component's created lifecycle hook.
	 *
	 * @return {void}
	 */
	async created() {
		await this.getCallData()

		if (this.enableHealthCheck) {
			const refreshIntervalInMinutes = this.healthCheckThreshold

			this.healthCheckInterval = setInterval(
				() => this.performHealthCheck(refreshIntervalInMinutes),
				refreshIntervalInMinutes * 60000
			)
		}
	},

	beforeDestroy() {
		if (this.enableHealthCheck) {
			clearInterval(this.healthCheckInterval)
		}
	},

	updated() {
		this.updateTodayAndYesterday()
	},

	/**
	 * Get the component's initial state.
	 *
	 * @return {Object}
	 */
	data() {
		return {
			autoload: true,
			busy: false,
			hasFetchedAllPages: false,
			sort: 'asc',
			today: moment().format('MM/DD/YYYY'),
			yesterday: moment()
				.subtract(1, 'day')
				.format('MM/DD/YYYY'),
		}
	},
}
</script>
